@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root{
    --main-font:  'Outfit', sans-serif;
    
}

body{
    font-family: var(--main-font);
}


#content h2{
  font-size: 1.5rem;

}

#content h3{

  font-size: 1.2rem;

}
#content h4{

  font-size: 1.1rem;

}
#content h1,h2,h3,h4,h5,h6{
   padding-top: 12px;
}

#content ul {
  list-style-type: disc;
  padding-left: 20px;
}

#content ol {
  list-style-type: decimal;
  padding-left: 20px;
}

#content ul li, #content ol li {
  margin-bottom: 6px;
  font-size: 16px;
}

pre{
  background-color: black;
  color: #7b7be6;
  padding: 6px 8px;
  border-radius: 6px;
  margin: 6px 0;
  overflow-x: scroll;
}